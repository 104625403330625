import React from 'react';
import { Controller } from 'react-hook-form';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiDatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

import { standardFormPropTypes, defaultStandardFormPropTypes } from '../../utils/proptypes';

const DatePicker = (props) => {
    const { control, name, label, isRequired = false } = props;

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <Controller
                name={name}
                control={control}
                rule={{ required: isRequired }}
                render={({ field: { onChange, value } }) => (
                    <MuiDatePicker
                        label={label}
                        value={value}
                        onChange={onChange}
                        minutesStep={5}
                        renderInput={(inputProps) => <TextField fullWidth {...inputProps} />}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

DatePicker.propTypes = {
    ...standardFormPropTypes,
};

DatePicker.defaultProps = {
    ...defaultStandardFormPropTypes
};

export default DatePicker;
